.slider {
  position: relative;
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  white-space: nowrap;
  transition: all 0.2s;
  will-change: transform;
  user-select: none;
  cursor: pointer;
  margin-bottom: -1.75rem;
  border-bottom-width: 0;

  .slider-item {
    display: inline-block;
    padding: 1em;
    color: var(--gray-dark);

    &--active {
      color: var(--primary);
      border-bottom: 2px solid var(--secondary);
    }

    &--inactive {
      color: var(--gray-dark);
      pointer-events: none;
    }
  }
}
