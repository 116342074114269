.button__inner {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
}

.button__spinner {
  position: absolute;
}
