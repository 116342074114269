.dashboard-content {
  min-height: 200;
  margin-bottom: 1.5rem;
}

.dashboard-content--fixed {
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1.5rem;
}
