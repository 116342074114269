.dashboard-table {
  th:global(.sticky),
  td:global(.sticky) {
    position: sticky;
    right: 0;
    z-index: 1;
  }

  td:global(.sticky) {
    background-color: white;
  }

  :global(.sorting):after,
  :global(.sorting_asc):after,
  :global(.sorting_desc):after {
    background-size: 0.7rem 0.7rem; // Fixed background issue in Safari
  }
}

.dashboard-table--fixed {
  table-layout: fixed;
}

.dashboard-table--collapse {
  border-collapse: collapse;
}

.dashboard-table--vertical-align-middle {
  thead tr th {
    vertical-align: middle;
  }
}
