:root {
  --primary: #330074;
  --secondary: #2ad2c9;
  --secondary-light: #eafbfa;
  --muted: #dee2e6;
}

.rmdp-wrapper {
  box-shadow: 0 0 var(--primary-light) !important;
}

.rmdp-week-day {
  color: black !important;
}

/* DAY */
.rmdp-day:not(.rmdp-day-hidden):not(.rmdp-range-hover):not(.rmdp-disabled) {
  border-radius: 50px;
  color: black;
  transition: background-color 0.1s ease, color 0.1s;
}
.rmdp-day:not(.rmdp-day-hidden):not(.rmdp-range-hover):not(.rmdp-disabled):hover {
  background-color: var(--secondary) !important;
  color: var(--secondary-light) !important;
}

.rmdp-day:not(.rmdp-day-hidden):not(.rmdp-disabled) span {
  transform: translate(-3px, -3px);
  height: 100%;
  width: 100%;
  color: black;
  border-radius: 50px;
  transition: background-color 0.1s ease, color 0.1s;
}

.rmdp-day:not(.rmdp-day-hidden):not(.rmdp-disabled) span:hover {
  background-color: var(--secondary) !important;
  color: var(--secondary-light) !important;
}
/* DAY */

/* TODAY */
.rmdp-today:not(.rmdp-range) {
  background-color: var(--primary-light) !important;
  color: var(--secondary) !important;
  border-radius: 50%;
  box-shadow: 0 0 3px var(--primary-light) !important;
}

.rmdp-today:hover {
  background-color: var(--primary-light) !important;
  color: var(--secondary) !important;
  border-radius: 50%;
}

.rmdp-today span {
  background-color: var(--primary-light) !important;
  color: var(--secondary) !important;
  box-shadow: 0 0 3px var(--primary-light) !important;
}

.rmdp-today span:hover {
  background-color: var(--primary-light) !important;
  color: var(--secondary) !important;
}
/* TODAY */

/* RANGE DATES */
.rmdp-range:not(.start):not(.end):not(.rmdp-range-hover) {
  background-color: var(--secondary-light) !important;
  color: black !important;
  border-radius: 0px !important;
  box-shadow: 0 0 3px var(--primary-light) !important;
}

.rmdp-range:not(.start):not(.end):not(.rmdp-range-hover) span {
  background-color: var(--secondary-light) !important;
  color: black !important;
  border-radius: 0px !important;
  box-shadow: 0 0 3px var(--primary-light) !important;
}
/* RANGE DATES */

/* RANGE HOVER */
.rmdp-range-hover:not(.rmdp-disabled):not(.end):not(.start) {
  transition: color 0s, background-color 0s !important ;
  background-color: var(--secondary-light);
  color: var(--secondary) !important;
  border-radius: 0px !important;
}

.rmdp-range-hover.rmdp-today:not(.rmdp-disabled):not(.end):not(.start) {
  transition: color 0s, background-color 0s !important;
  background-color: var(--secondary-light) !important;
  color: var(--secondary) !important;
  border-radius: 0px !important;
}
/* RANGE HOVER */

/* START DATE */

.start {
  background-color: var(--secondary) !important;
  border-radius: 30px 0px 0px 30px !important;
  box-shadow: 0 0 3px var(--primary-light) !important;
}

.start span {
  color: var(--secondary-light) !important;
}

/* START DATE */

/* END DATE */
.end {
  background-color: var(--secondary) !important;
  border-radius: 0px 30px 30px 0px !important;
  box-shadow: 0 0 3px var(--primary-light) !important;
}

.end span {
  color: var(--secondary-light) !important;
}
/* END DATE */

/* SELECTED */
.rmdp-selected {
  background-color: var(--secondary) !important;
}

.rmdp-selected:not(.rmdp-today) span {
  background-color: var(--secondary) !important;
  box-shadow: 0 0 3px var(--primary-light) !important;
}

.rmdp-selected.rmdp-today span {
  color: var(--secondary-light) !important;
  background-color: var(--secondary) !important;
  box-shadow: 0 0 3px var(--primary-light) !important;
}

/* SELECTED */

/* DISABLED */

.rmdp-disabled span {
  background-color: var(--primary-);
  color: var(--muted) !important;
}

.rmdp-disabled.rmdp-range-hover {
  background-color: var(--primary-light) !important;
}

.rmdp-disabled span:hover {
  background-color: var(--primary-light) !important;
}

/* DISABLED */

.rmdp-day.rmdp-today.rmdp-range-hover.end {
  background-color: var(--secondary) !important;
  color: var(--secondary-light) !important;
  border-radius: 0px 30px 30px 0px !important;
}

.rmdp-day-picker {
  width: 250px;
}

.rmdp-month-picker {
  width: 250px;
}

.rmdp-year-picker {
  width: 250px;
}
